.hike-start-icon {
    padding: 9px;
    border: 2px solid white;
    border-radius: 15px;
    position: absolute;
    background-color: green;
    width: 0;
    height: 0;
}
.user-position-icon {
    transform-origin: center;
}
.user-position-no-accuracy-icon {
    padding: 2px;
    border-radius: 14px;
    border: 2px solid white;
    background-color: darkgrey;
}
.user-position-icon-heading {

}
.my-location-container {
    position: relative;
}
.location-range-icon {
    position: absolute;
    opacity: 0.3;
    border: 1px solid #4285f4;
    background-color:rgba(66, 133, 244, 0.3);
    border-radius: 100px;
}