.MuiCardHeader-root {
  padding: 0 !important;
  width: 100% !important;
}
.MuiCardHeader-title {
  font-weight: 500 !important;
  margin-bottom: 3px !important;
}
.MuiCardHeader-content {
  width: 100% !important;
}
.MuiCardHeader-title,
.MuiCardHeader-subheader {
  font-size: 1em !important;
}
.MuiIconButton-root {
  padding: 5px;
}
