body {
  font-size: 0.9em;
  color: white;
  font-family: "Montserrat Alternates", sans-serif;
}
h1 {
  font-family: inherit;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 34px;
}
h2 {
  font-family: inherit;
  font-size: 16px;
  line-height: 26px;
}
.constraint-title {
  line-height: 22px;
  font-weight: bold;
}
@media (max-width: 320px), (max-height: 610px) {
  h1 {
    font-size: 20px;
    line-height: 24px;
  }
  h2 {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (max-height: 610px) {
  .search p.MuiTypography-body1 {
    font-size: 14px !important;
    line-height: 5px !important;
    font-weight: bold !important;
  }
}

.normal-page {
  padding-top: 1px;
}

.MuiIconButton-root,
.MuiBottomNavigationAction-root {
  color: inherit; /* inherit text color */
}

.MuiTypography-body2.textSecondary {
  color: white;
}

/* Feedback */
.feedback-input {
  margin-top: 12px;
}

.feedback-input * {
  color: #fff;
  font-size: 14px;
}

.feedback-input ::before,
.feedback-input ::after {
  border-color: #fff;
}

.feedback-input .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-color: #fff;
}

.feedback-input .MuiInputBase-formControl {
  margin-bottom: 5px;
}

.feedback-input .MuiInputBase-input {
  margin-bottom: 0;
}

.feedback-input .MuiFormHelperText-root {
  font-size: 11px;
  margin-top: 0;
}

.MuiTimelineContent-root {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.MuiTimelineDot-root {
  margin: 8px 0;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
