.custom-location-page .leaflet-top.leaflet-right {
  position: absolute;
  z-index: 401;
  bottom: 96px;
  right: 20px;
  left: auto;
  top: auto;
}

.custom-location-page .leaflet-top.leaflet-right .leaflet-bar {
  margin: 0;
}
