.navButton {
  opacity: 0.3
}

.navButton:hover {
  opacity: 1
}

.indicatorContainer {
  width: 100%;
  position: relative;
  margin-top: -20%;
  text-align: center;
}

.indicatorIconButton {
  z-index: 1;
  color: #a4371b;
  opacity: 0.5;
}

.activeIndicatorIconButton {
  opacity: 1;
}
