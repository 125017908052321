bottomSheet.MuiBackdrop-root {
    background-color: transparent !important; /* Remove background opacity to bottom sheet */
}

.dragger {
    width: 5px;
    cursor: ew-resize;
    padding: 4px 0 0;
    borderTop: 1px solid #ddd;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    zIndex: '100';
    backgroundColor: '#f4f7f9'
}
